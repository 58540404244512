ol#stepper {
  counter-reset: taskCounter;
  background-repeat: repeat-y;

  > li {
    @apply relative flex items-start gap-4;

    padding-bottom: 40px;

    & h3 {
      @apply mb-1;
    }

    &:not(:last-child)::after {
      @apply absolute h-full;

      z-index: 0;
      width: 1px;
      content: " ";
      left: 22px;
      height: calc(100% - 50px);
      margin-top: 50px;
      background: repeating-linear-gradient(
        to bottom,
        black 0,
        black 2px,
        transparent 4px,
        transparent 6px
      );
    }

    &::before {
      width: 45px;
      height: 45px;
      font-weight: 400;
      text-align: center;
      z-index: 1;
    }

    &:not(.subtask)::before {
      content: counter(taskCounter, decimal-leading-zero) ".";
      font-family: $header-font;
      font-size: 2rem;
      letter-spacing: 0.03rem;
      counter-increment: taskCounter;
    }

    &.subtask::before {
      @apply flex items-center justify-center;

      content: url("../../../public/images/freeze/step-subtask.svg");
      height: 45px;
      width: 45px;
      background-size: 2px;
      z-index: 1;
    }

    &.active {
      &::before {
        background-image: url("../../../public/images/freeze/step-active.svg");
        background-repeat: no-repeat;
      }
    }

    & .duration {
      @apply mt-2;
    }

    li {
      list-style: none;
    }
  }
}

#welcome {
  footer {
    .stepper {
      transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56) 0ms;
    }
  }
}
