@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeueLight.woff2") format("woff2"),
    url("../fonts/HelveticaNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeue.woff2") format("woff2"),
    url("../fonts/HelveticaNeue.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeueMedium.woff2") format("woff2"),
    url("../fonts/HelveticaNeueMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeueBold.woff2") format("woff2"),
    url("../fonts/HelveticaNeueBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SeriouslyNostalgic;
  src: url("../fonts/SeriouslyNostalgicItal-Reg.woff2") format("woff2"),
    url("../fonts/SeriouslyNostalgicItal-Reg.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: SeriouslyNostalgic;
  src: url("../fonts/SeriouslyNostalgic-Regular.woff2") format("woff2"),
    url("../fonts/SeriouslyNostalgic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
