.navigation a {
  @apply mx-1 uppercase text-base text-neutral-600 tracking-widest;

  &.active {
    @apply font-medium inline-flex justify-center relative text-black;

    &::after {
      @apply content-[""] absolute -bottom-1.5 h-0.5 w-4/5 bg-black;
    }
  }
}

.navigation div {
  @apply mx-1 uppercase text-base text-neutral-600 tracking-widest;

  &.active {
    @apply font-medium inline-flex justify-center relative text-black;

    &::after {
      @apply content-[""] absolute -bottom-1.5 h-0.5 w-4/5 bg-black;
    }
  }

  &.active-w {
    @apply font-medium inline-flex justify-center relative text-white;

    &::after {
      @apply content-[""] absolute -bottom-1.5 h-0.5 w-4/5 bg-white;
    }
  }
}

.header-menu-button {
  @apply flex justify-center items-center pb-3;

  &::after {
    @apply h-8 w-8 bg-chevron-down bg-no-repeat bg-cover bg-center;

    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
  }

  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }
}

.mobile-menu {
  @apply fixed bg-primary w-full h-full lg:hidden;

  .bottom-menu {
    a {
      @apply text-base1;
    }
  }

  .top-menu div {
    @apply text-3xl font-normal;

    font-family: $header-font;

    &:not(:last-child) {
      @apply mb-4;
    }
  }
}

.mobile-menu-family {
    @apply fixed bg-secondary w-full h-full lg:hidden;

    .bottom-menu {
        a {
            @apply text-base1;
        }
    }

    .top-menu div {
        @apply text-3xl font-normal;

        font-family: $header-font;

        &:not(:last-child) {
            @apply mb-4;
        }
    }
}
