#inner-body.hold-match {
    background-color: var(--secondary) !important;
    background-image: url("../../../public/images/hold-match-purple-circle.png"), url("../../../public/images/hold-match-orange-circle.png");
    background-position: 52% 100%, right top;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 100vh;
}

#hold-match-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    margin: auto;
    gap: 150px;

    .left-section {
        flex: 1;
        max-width: 50%;
        text-align: center;
        margin-top: 16vh;


    }

    .right-section {
        flex: 1;
        max-width: 50%;
        border-radius: 10px;
        padding: 20px;
        color: black;
    }

    .divider {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        color: var(--dark-grey);
        font-size: 14px;

        span {
            padding: 5px 15px;
            border: 1px solid var(--dark-grey);
            ;
            border-radius: 20px;
            background-color: white;
            font-weight: bold;
        }
    }

    .divider::before,
    .divider::after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: var(--dark-grey);
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .form {
        input {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 14px;
        }

        input:focus {
            border-color: var(--secondary);
            outline: none;
        }
    }
}

.profile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 0;
    margin-top: 1rem;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }

    .icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #8c9c68;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: var(--secondary);
    }

    .donor-oocyte {
        min-width: 200px;
        position: relative;
        z-index: 2;
        transform: translate(30px, 20px);
    }

    .ip-oocyte {
        min-width: 200px;
        position: relative;
        z-index: 1;
        transform: translate(-20px, -10px);
    }
}

@media (max-width: 768px) {
    #hold-match-container {
        flex-direction: column;
        align-items: center;

        .left-section,
        .right-section {
            max-width: 100%;
        }
    }
}
