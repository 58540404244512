#schedule {
  max-width: 808px;

  .flatpickr-calendar {
    @apply shadow-none bg-transparent text-base px-0 sm:pt-10 pt-5 #{!important};

    .flatpickr-current-month,
    .flatpickr-weekdays,
    .flatpickr-weekday,
    .flatpickr-month,
    .flatpickr-innerContainer {
      @apply bg-transparent;
    }

    .flatpickr-weekdays {
      @apply mb-5;

      font-size: 100%;
    }

    .flatpickr-current-month {
      @apply text-left left-0 pointer-events-none pt-0 #{!important};

      & .numInputWrapper {
        margin-left: 0 !important;
      }
    }

    .flatpickr-rContainer {
      flex: 1;

      .flatpickr-days {
        width: auto;

        .dayContainer {
          @apply w-full min-w-full max-w-full gap-y-5 #{!important};

          gap: 4%;
        }
      }
    }

    .cur-month,
    .cur-year {
      @apply font-bold text-base text-neutral-900;
    }

    .flatpickr-day {
      @apply aspect-square w-auto h-auto flex items-center bg-primary font-bold #{!important};

      flex: 1 0 10%;
      max-width: inherit;

      &.today {
        border: none;

        &::after {
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          bottom: 4px;
          left: calc(50% - 1.5px);
          content: " ";
          display: block;
          background: theme("colors.neutral.600");
        }

        &:hover {
          color: theme("colors.neutral.600");
          border: 1px solid theme("colors.freeze.secondary.500");
        }
      }

      &.prevMonthDay,
      &.nextMonthDay {
        @apply invisible;
      }

      &.selected {
        @apply bg-freeze-secondary border-freeze-primary #{!important};

        &:hover {
          @apply bg-freeze-secondary #{!important};
        }
      }

      &.flatpickr-disabled,
      &.notAllowed {
        font-weight: 300 !important;
        border: none;
        background: none !important;
        color: theme("colors.neutral.600") !important;

        @apply hover:bg-transparent hover:border-0 font-light border-none bg-none #{!important};
      }

      &:hover {
        @apply bg-primary border-secondary  #{!important};
      }
    }

    .flatpickr-next-month,
    .flatpickr-prev-month {
      padding: 0 !important;
      width: 32px;
      height: 32px;
    }

    .flatpickr-months {
      .flatpickr-prev-month {
        right: 42px;
        left: auto !important;
      }
    }
  }

  #confirm-appointment {
    @apply flex flex-col grow items-stretch;
  }
}
