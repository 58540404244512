#faq {
  @apply max-w-container mt-0;

  a {
    @apply underline;
  }

  /* restores numbered format to ol items. If used in more places we'll want to move this! */
  ol {
    @apply list-decimal list-outside;

    li {
      @apply ml-6;
    }
  }

  .answer {
    img {
      @apply w-full py-4;
    }
  }
}
