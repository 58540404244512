// We can't control the class names so:

.flatpickr-calendar {
  width: auto !important;
  padding: 10px !important;
  border-radius: 15px !important;

  @apply bg-white;
}

.flatpickr-current-month .numInputWrapper {
  margin-left: 0.5em !important;
}

.flatpickr-months .flatpickr-month,
.flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  @apply bg-white;
}

.flatpickr-current-month input.cur-year,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  border-radius: 3px !important;
}

.flatpickr-months {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply fill-red-500 #{!important};
}

.flatpickr-weekdays {
  padding: 25px 0 15px;
}

.flatpickr-innerContainer {
  border: 0 !important;
}

.flatpickr-days {
  border: 0 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply bg-red-500 border-red-500 #{!important};
}
