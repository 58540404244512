#photos,
#documents {
  .uppy-Dashboard-AddFiles {
    @apply m-4;

    border-color: $black;
    border-width: 2px;

    .uppy-Dashboard-AddFiles-title {
      @apply absolute sm:static;
    }

    .uppy-Dashboard-browse {
      @apply block w-full;

      border-bottom: 0;
      font-weight: 400;
      font-size: 1.25rem; /* 20px */
      line-height: 1.3125rem; /* 21px */
      font-family: $header-font;
      letter-spacing: 0.06rem;
      color: $black;

      &::before {
        @apply mb-2;

        display: block;
        content: url("../../../public/images/freeze/upload.svg");
      }
    }
  }

  .uppy-DashboardContent-back,
  .uppy-DashboardContent-addMore,
  .uppy-DashboardContent-title {
    @apply text-sm;

    color: $black;
  }

  .uppy-DashboardContent-addMore {
    @apply p-0 leading-none;

    &::before {
      content: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="5" width="1" height="22" fill="%23191919"/><rect x="27.5" y="15.5" width="1" height="22" transform="rotate(90 27.5 15.5)" fill="%23191919"/></svg>');
    }

    & > svg {
      display: none;
    }
  }

  .uppy-Dashboard-Item-name,
  .uppy-Dashboard-Item-statusSize {
    @apply text-sm font-light;
  }

  .uppy-Dashboard-Item-previewInnerWrap {
    @apply rounded-lg;
  }

  .uppy-Dashboard-Item-action--edit {
    @apply h-8 w-8;

    & > svg {
      display: none;
    }

    &::after {
      @apply h-8 w-8;

      content: url("../../../public/images/edit.svg");
    }
  }

  .uppy-Dashboard-Item-action--remove {
    @apply h-8 w-8;

    & > svg {
      @apply hidden;
    }

    &::after {
      @apply h-8 w-8 block;

      content: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6.83344" width="1.1785" height="27.1055" rx="0.589251" transform="rotate(-45 6 6.83344)" fill="%23191919"/><rect x="25.167" y="6" width="1.1785" height="27.1055" rx="0.589251" transform="rotate(45 25.167 6)" fill="%23191919"/></svg>');

      @media (min-width: theme("screens.sm")) {
        content: url("../../../public/images/delete.svg");
      }
    }
  }

  .uppy-StatusBar-actions {
    @apply justify-end;

    button {
      @apply button-primary border-solid transition-none items-center gap-2;
      @apply py-2 #{!important};

      min-width: auto !important;

      &.uppy-StatusBar-actionBtn--retry {
        @apply h-auto flex items-center;

        svg {
          @apply static h-4 w-4;
        }
      }

      &::after {
        content: url('data:image/svg+xml, <svg height="32" width="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z" fill="%23065951"/></svg>');
        height: 32px;
        width: 32px;
      }

      &:hover {
        &::after {
          content: url('data:image/svg+xml, <svg height="32" width="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z" fill="%23D2FC99"/></svg>');
        }
      }
    }
  }

  .uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
    min-height: 76px;
  }

  div[class*="is-"].uppy-StatusBar:not(.is-waiting) {
    min-height: 76px;
  }

  .uppy-Dashboard-progressindicators {
    @apply border-t border-black;
  }

  .uppy-DashboardContent-bar {
    @apply border-b border-black py-5 h-auto;

    .uppy-DashboardContent-back {
      order: 1;
    }

    .uppy-DashboardContent-save,
    .uppy-DashboardContent-addMore {
      order: 3;
    }

    .uppy-DashboardContent-title {
      @apply font-bold static leading-normal;

      order: 2;
      color: $black;
    }
  }

  .uppy-Dashboard-inner {
    @apply md:container;
    @apply rounded-lg shadow-none lg:top-auto lg:h-1/2;

    border: 2px solid $black;
  }

  .uppy-Dashboard-close {
    @apply -top-13 right-0;

    & > span {
      display: none;
    }

    &::before {
      @apply block rotate-45 h-13 w-13;

      content: url('data:image/svg+xml,<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="5" width="1" height="22" fill="white"/><rect x="27.5" y="15.5" width="1" height="22" transform="rotate(90 27.5 15.5)" fill="white"/></svg>');
    }
  }

  .uppy-Dashboard-Item-preview {
    min-height: 78px;
    min-width: 78px;
  }

  .uppy-Dashboard--modal .uppy-Dashboard-overlay {
    @apply bg-black bg-opacity-90 md:bg-black md:bg-opacity-50;
  }
}
