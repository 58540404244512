@import "colors";
@import "typography";
@import "fonts";
@import "forms";
@import "flatpickr";

/* sticky footer */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  cursor: default;

  // Example font-family inclusion
  // font-family: "GT Walsheim Pro", sans-serif;
}

html {
  -webkit-tap-highlight-color: transparent;
}

#inner-body,
#app {
  @apply flex;

  flex: 1;
  flex-direction: column;
}

#loading-body {
  position: fixed;
  top: 50%;
  z-index: 40;
}

/* alpine */
[x-cloak] {
  display: none !important;
}
