/* stylelint-disable block-no-empty */

/*
  This file controls the styling for "material" inputs.

  inputs are styled in the order of .field > .input-box > [input]
  rem units are being used to keep things proportional on the tailwind side,
  but we have a bit less control over t-s (tom-select) which is being styled using px.
*/

// base guidelines for styles. All assume a base font size of 16
$input-height: 3.5rem; // 56px
$border-radius: 0.625rem; // 10px
$focus-color: var(--primary-focus); // Outline of focused text elements
$focus-label: var(--primary); // color of focused label
$border-color: var(--black); // border color for inputs
$background-color: #fff;

// Neither of these animations have a visible effect - they are used as flags for autofill
@keyframes autofill-start {
  from {
  }

  to {
  }
}

@keyframes autofill-cancel {
  from {
  }

  to {
  }
}

.select {
  overflow-x: visible !important;
  min-height: $input-height; // prevent flash of content from tom select starting up
  // basic styles for no js
  select {
    border-width: 0;
    width: 100%;
  }

  select ~ label {
    // z-index: 2;
  }
}

// TODO: add input styling for family once we get designs

.field {
  // wrapper element for input/label, provides basic styling for input
  @apply relative h-auto flex flex-col-reverse;

  &.error {
    @apply flex flex-col-reverse w-full border-error text-error;
    @apply focus:outline-none focus:ring-error focus:border-error;
  }

  & + .errorlist {
    @apply -mx-5;
  }

  label {
    @apply text-base mb-2;
  }

  input:not([type="checkbox"], [type="radio"]),
  textarea {
    @apply p-4 ring-0 w-full border leading-snug;

    border-color: $border-color;
    border-radius: $border-radius;
    background-color: $background-color !important;

    &:read-only {
      @apply pointer-events-none;
    }

    &:disabled {
      @apply border-neutral-500 text-neutral-500;
    }

    // these are placeholder animations which allow us to attach an event listener
    // to inputs that have been autofilled. Without a duration, styles are applied with
    // "automatic" autofill but not with drop down. Props to the Klarna UI team for
    // this solution.
    &:-webkit-autofill {
      animation-name: autofill-start;
      animation-duration: 0.001s;
    }

    &:not(:-webkit-autofill) {
      animation-name: autofill-cancel;
      animation-duration: 0.001s;
    }

    &[type="password"] {
      @apply pr-12;
    }
  }

  // Font-end styling for tom-select
  .ts-wrapper {
    @apply ring-0 w-full left-0 bg-white pr-0;

    height: $input-height !important;
    border-radius: 5px;

    + label {
      // gives select room for the drop down arrow
      @apply pr-10;
    }

    &.multi {
      padding-top: 20px !important;
    }

    .ts-control {
      @apply py-3 border-0 w-full bg-white pl-0 border border-black border-solid;
      @apply pl-4 pr-8 #{!important};

      z-index: 0 !important;
    }

    .ts-dropdown {
      margin: 0;
      border-radius: 8px;
      left: -16px;
      width: calc(100% + 16px);
      color: $black;
      z-index: 1;

      .ts-dropdown-content {
        padding: 0;

        .option {
          line-height: 48px;
          border-radius: 6px;
          margin: 4px;
        }
      }
    }
  }

  // removes input styling that some browsers apply to autofilled elements
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-font-size: inherit;
    font-size: inherit;
  }
}

// global/admin styling for tom-select
.ts-wrapper {
  @apply px-4 ring-0;

  &.focus {
    .ts-control {
      box-shadow: none;
      border-color: var(--primary-accent);
    }
  }

  .ts-control {
    input {
      height: inherit;
      width: auto;
      display: inline-flex !important;
      min-width: 0;
    }

    .item {
      display: inline-flex;
    }
  }

  .ts-dropdown {
    padding: 2px; // Prevents scrollbar from overlapping border
    border: 1px solid var(--dark-gray);
    color: var(--primary);

    .active {
      @apply bg-opacity-10;

      color: var(--primary-focus);
    }

    .option {
      padding-left: 16px;
      margin: 4px;
      height: $input-height;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .ts-dropdown-content {
      padding: 0;

      .option {
        line-height: 48px;
        border-radius: 6px;
        margin: 4px;

        &.selected {
          text-decoration: none;
        }
      }
    }
  }
}

.left-icon {
  @apply inset-y-0 relative left-0 flex items-center pointer-events-none z-10;

  background-color: $background-color;
}

.right-icon {
  @apply inset-y-0 absolute right-0 flex items-center pointer-events-none z-10;

  background-color: $background-color;
}

.form-wrapper {
  @apply flex flex-row flex-wrap gap-7;
}

/*
  Base styles of all buttons- branched depending on primary/secondary/theme
*/
@mixin button {
  @apply rounded-full border-2 text-center inline-flex items-center justify-center;
  @apply focus:ring-2 focus:ring-offset-2 px-6 py-3 text-lg min-w-full;
}

@mixin file-button {
  @apply file:rounded-full file:border-2 file:text-center file:inline-flex file:items-center;
  @apply file:justify-center file:focus:ring-2 file:focus:ring-offset-2 file:px-6 file:py-3;
  @apply file:text-lg file:cursor-pointer file:my-2 file:mx-2 file:border-solid;
}

@mixin freeze-button {
  @include button;
  @apply focus:ring-freeze-secondary-500  hover:border-freeze-primary;
  @apply hover:text-freeze-primary hover:bg-freeze-secondary font-normal;
}

// color styling unique to each platform
.freeze {
  input {
    @apply focus:ring-freeze-secondary focus:border-freeze-secondary;
  }

  .button-primary:not([type="file"]) {
    @include freeze-button;
    @apply text-freeze-secondary bg-freeze-primary;
    @apply border-freeze-secondary;
  }

  .button-primary[type="file"] {
    @include file-button;
    @apply file:text-freeze-secondary file:bg-freeze-primary;
    @apply file:border-freeze-secondary file:hover:text-freeze-primary;
    @apply file:hover:bg-freeze-secondary file:hover:border-freeze-primary;
  }

  .button-primary:disabled {
    @apply bg-neutral-300 border-neutral-300 text-white;
  }

  .button-secondary {
    @include freeze-button;
    @apply border-freeze-secondary;
  }
}

.button-default {
  @include button;
  @apply bg-black hover:text-black hover:border-black hover:bg-primary text-white-200;

  & span {
    @apply text-white-200 hover:text-black;
  }

  &:hover span {
    @apply text-black;
  }
}

.button-google {
    @include button;
    @apply border-black hover:bg-black hover:text-white hover:border-black;
}

.button-family-signup {
    @include button;
    @apply bg-family-apricot border-family-apricot text-black hover:bg-family-forest-500 hover:border-family-forest-500 hover:text-white;
}

.button-freeze-signup {
    @include button;
    @apply bg-freeze-primary border-family-forest-500 text-secondary hover:bg-family-forest-500 hover:border-family-forest-500 hover:text-white;
}
