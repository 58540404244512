// Sass Color variables
$primary: #2d36a8;
$primary-focus: #065951;
$secondary: #065951;
$accent: #111827;
$accent-focus: #030509;
$error: theme("colors.error.DEFAULT");
$error-focus: #991b1b;
$error-content: #eceded;
$warning: #e3d65d;
$warning-focus: #854d0e;
$warning-content: theme("colors.black.DEFAULT");
$success: theme("colors.freeze.secondary.50");
$success-focus: #166534;
$success-content: #191919;
$info: #333;
$info-focus: #1e40af;
$info-content: #eceded;
$white: #fff;
$grey: #d1d5db;
$dark-grey: #d8d8d8;
$grey-focus: #e5e8ed;
$black: #191919;

// CSS Custom Properties for use in tailwind config
:root {
  --accent: #{$accent};
  --accent-focus: #{$accent-focus};
  --accent-content: #{$white};
  --primary: #{$primary};
  --primary-focus: #{$primary-focus};
  --primary-content: #{$white};
  --secondary: #{$secondary};
  --secondary-focus: #{$grey-focus};
  --secondary-content: #{$black};
  --error: #{$error};
  --error-focus: #{$error-focus};
  --error-content: #{$error-content};
  --warning: #{$warning};
  --warning-focus: #{$warning-focus};
  --warning-content: #{$warning-content};
  --success: #{$success};
  --success-focus: #{$success-focus};
  --success-content: #{$success-content};
  --info: #{$info};
  --info-focus: #{$info-focus};
  --info-content: #{$info-content};
  --grey: #{$grey};
  --dark-grey: #{$dark-grey};
  --grey-focus: #{$grey-focus};
  --slate: #54565a;
  --silver: #b1b1b1;
}
