#profile {
  .nav-link {
    button {
      @apply uppercase font-normal flex flex-col items-center;

      &::after {
        @apply flex w-13 h-0.5 mx-auto top-1.5 relative;

        content: " ";
      }

      &::before {
        // this isa nice lil hack that will allow us to bold text without shifting layout
        @apply block font-bold h-0 overflow-hidden invisible;

        content: attr(title);
      }

      &:hover,
      &.current {
        @apply font-bold text-freeze-secondary;

        &::after {
          @apply bg-freeze-secondary;
        }
      }
    }
  }

  .slider {
    position: relative;

    &::before {
      display: block;
      content: " ";
      width: 100%;
      height: 2px;
      background-color: black;
      position: absolute;
      top: calc(50% - 2px);
      border-radius: 8px;
      z-index: 0;
    }
  }
}
