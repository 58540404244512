.pizza-tracker-item {
  @apply shadow-soft flex pl-3 pr-6 bg-white-200 relative justify-between items-center border border-black;
  @apply rounded-r-full md:rounded-r-none mb-3;

  min-width: 240px;

  &:last-child {
    @apply md:rounded-r-full;
  }

  &.active {
    @apply bg-tertiary-dark text-white-200;
  }

  .pizza-tracker-title {
    @apply italic text-xl pr-1 pl-5 py-1 leading-5 font-display;
  }

  .pizza-tracker-order {
    @apply absolute -left-4 w-8 h-8;
  }
}

.pizza-tracker-order {
  @apply bg-white-200 border border-black rounded-full w-10 h-10 inline-flex;
  @apply justify-center items-center text-base font-bold;

  &.active {
    @apply bg-primary text-black;
  }
}

.pizza-tracker-item-family {
    @apply flex pl-3 pr-6 bg-white-200 relative justify-between items-center border border-family-forest-500 text-family-forest-500;
    @apply rounded-r-full md:rounded-r-none mb-3;

    min-width: 240px;

    &:last-child {
        @apply md:rounded-r-full;
    }

    &.active {
        @apply bg-[#CACD9D] text-family-forest-800;
    }

    .pizza-tracker-title {
        @apply italic text-xl pr-1 pl-5 py-1 leading-5 font-display;
    }

    .pizza-tracker-order-family {
        @apply absolute -left-4 w-8 h-8;
    }
}

.pizza-tracker-order-family {
    @apply bg-white-200 border border-family-forest-500 text-family-forest-500 rounded-full w-10 h-10 inline-flex;
    @apply justify-center items-center text-base font-bold;

    &.active {
        @apply bg-[#ABAF70] text-family-forest-800;
    }
}