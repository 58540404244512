/* stylelint-disable */
$body-font: helveticaNeue, sans-serif;
$header-font: seriouslyNostalgic, serif;
/* stylelint-enable */

@layer base {
  body {
    @apply antialiased;

    font-weight: 300;
    font-family: $body-font;
    letter-spacing: 0.03rem;
  }

  .display {
    font-weight: 400;
    line-height: 2.8125rem; /* 45px */
    font-size: 2.375rem; /* 38px */
    font-family: $header-font;
    letter-spacing: 0.03rem;
  }

  .heading-underline {
    /* stylelint-disable */
    --accent: theme(colors.primary.DEFAULT);
    background-image: linear-gradient(
      transparent,
      transparent 67%,
      var(--accent) 67%,
      var(--accent) 87%,
      transparent 87%,
      transparent
    );
    /* stylelint-enable */
  }

  .heading-underline-forest-50 {
    /* stylelint-disable */
    --accent: #A7C1BE;
    background-image: linear-gradient(
      transparent,
      transparent 67%,
      var(--accent) 67%,
      var(--accent) 87%,
      transparent 87%,
      transparent
    );
    /* stylelint-enable */
  }

  .heading-underline-goldenrod {
    /* stylelint-disable */
    --accent: #CACD9D;
    background-image: linear-gradient(
      transparent,
      transparent 67%,
      var(--accent) 67%,
      var(--accent) 87%,
      transparent 87%,
      transparent
    );
    /* stylelint-enable */
  }

  .heading-underline-tertiary {
    /* stylelint-disable */
    --accent: theme(colors.tertiary.light);
    background-image: linear-gradient(
      transparent,
      transparent 67%,
      var(--accent) 67%,
      var(--accent) 87%,
      transparent 87%,
      transparent
    );
    /* stylelint-enable */
  }

  h1 {
    @apply mb-3;

    font-weight: 400;
    line-height: 2.375rem; /* 38px */
    font-size: 2rem; /* 32px */
    font-family: $header-font;
    letter-spacing: 0.06rem; /* 0.96px */
  }

  h2 {
    @apply mb-2;

    font-weight: 400;
    line-height: 2.0625rem; /* 33px */
    font-size: 1.75rem; /* 28px */
    font-family: $header-font;
    letter-spacing: 0.0525rem; /* 0.84px */
  }

  h3 {
    @apply mb-2;

    font-weight: 400;
    line-height: 2.0625rem; /* 33px */
    font-size: 1.5rem; /* 24px */
    font-family: $header-font;
    letter-spacing: 0.045rem; /* 0.72px */
  }

  h4 {
    @apply mb-1;

    font-weight: 400;
    font-size: 1.25rem; /* 20px */
    line-height: 1.3125rem; /* 21px */
    font-family: $header-font;
    letter-spacing: 0.0375rem; /* 0.6px */
  }

  ul {
    @apply list-disc list-inside;
  }

  .eyebrow {
    font-family: $body-font;
    font-weight: 700;
    font-size: 1rem; /* 16px */
    line-height: 1.25rem; /* 20px */
    text-transform: uppercase;
  }

  .subtitle-large {
    font-family: $body-font;
    font-weight: 700;
    font-size: 1.125rem; /* 18px */
    line-height: 1.625rem; /* 26px */
    letter-spacing: 0.03375rem; /* 0.54px */ /* stylelint-disable-line number-max-precision */
  }

  .subtitle-md {
    font-family: $body-font;
    font-weight: 700;
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    letter-spacing: 0.03rem;
  }

  .subtitle-small {
    font-family: $body-font;
    font-weight: 700;
    font-size: 0.875rem; /* 14px */
    line-height: 1.375rem; /* 22px */
    letter-spacing: 0.02625rem; /* 0.42px */ /* stylelint-disable-line number-max-precision */
  }

  .text-xs {
    @apply font-normal;
  }

  .error-text {
    @apply text-xs text-error mt-1;
  
    line-height: 0.875rem;
  }
  
  .help-text {
    @apply text-xs text-black mt-1;
  
    line-height: 0.875rem;
  }
  
  strong {
    @apply font-bold;
  }
}


